<template>
  <nav class="header-menu menu nav">
      <!-- menu list -->
      <MenuList></MenuList>
      <!-- header btn -->
      <ul class="menu-btns">
        <template v-if="login">
          <li class="header__account--items">
            <a href="/profile/dashboard" style="display: flex;flex-direction: column;height: 100%;align-items: center;justify-content: center">
              <img src="@/images/tmp/user.png" width="32" alt="user">
<!--              <em class="ni ni-user-fill"></em>-->
<!--              <span class="menu-title menu-email">{{ email }}</span>-->
            </a>
          </li>
          <li>
            <!--            sign-in-->
            <a href="#" class="btn btn-grad" @click="logout"> SIGN OUT</a>
          </li>
        </template>
        <template v-else>
          <li>
            <!--           btn btn-dark menu-title -->
            <ButtonLink text="SIGN IN" link="/sign-in" classname="sign-in" ></ButtonLink>

          </li>
          <li>
            <!--           btn btn-outline-dark menu-title -->
            <ButtonLink text="SIGN UP" link="/sign-up" classname="sign-up" ></ButtonLink>

<!--                                     <ThemeSwitcher></ThemeSwitcher>-->
          </li>
        </template>
      </ul>
  </nav><!-- .header-menu -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

// @ is an alias to /src
import MenuList from '@/components/n1/MenuList.vue'
import storage from "@/storage";
import accountApi from "@/apis/accountApi";
import {ElLoading} from "element-plus"
export default {
  name: 'Menu',
  props: ['classname'],
  components: {
    MenuList
  },
  data () {
    return {
      SectionData,
      login: false,
      email: ''
    }
  },
  mounted() {
    let userId = storage.localGet("userId")
    if(userId){
      this.login = true;
      this.email = userId.email;
    }
  },
  methods:{
    logout() {
      const load = ElLoading.service({
        lock: true,
        text: 'Sign Out',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      accountApi.logout(() => {
        load.close()
        storage.localDel("userId")
        this.login = false;
        localStorage.clear()
        if (location.href !== "/") {
          location.href = "/"
        } else {
          location.reload()
        }

      })
    },
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 992px){
  .header-menu{
    flex: 1;
    width: 0;
  }
  .menu-title{
    font-size: 14px;
  }
  .menu-email{
    display: none;
  }
  .btn{
    padding: 8px 6px;
  }
  .ni-user-fill{
    margin-right: 6px;
  }
}

@media (min-width: 1200px){
  .btn{
    padding: 8px 12px;
  }
  .menu-title{
    font-size: 16px;
  }
  .menu-email{
    display: flex;
  }
}




</style>
