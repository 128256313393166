<template>
  <div class="container">
    <div class="section-space-sm">
      <div class="row">
        <div class="col-lg-4 col-md-6 me-auto">
          <div class="footer-item mb-5 mb-lg-0">
            <!-- logo link -->
            <LogoLink class="footer-logo-link" src="logo-light.png"></LogoLink>
            <p class="my-4 footer-para" v-if="footerData.content">{{ footerData.content }}</p>
            <ul class="styled-icon">
              <li>
                <a href="https://GDMining.com/download">
<!--                  <img class="s-app" src="@/images/tmp/app-down.png" alt="app-download">-->
                  <img class="s-app" src="@/images/tmp/d_android.png" alt="android-download">
                  <img class="s-app" src="@/images/tmp/d_ios.png" alt="ios-download">
                </a>
              </li>
            </ul>
          </div><!-- end footer-item -->
        </div><!-- end col-lg-3 -->
        <div class="col-lg-8">
          <div class="row g-gs">
            <div class="col-lg-4 col-md-4 col-sm-4" v-for="item in footerData.footerList" :key="item.id">
              <div class="footer-item">
                <h5 class="mb-4 footer-para">{{ item.title }}</h5>
                <ul class="list-item list-item-s1">
                  <li v-for="(list, i) in item.navList" :key="i">
                    <a :href="list.path" v-if="list.path.indexOf('http')!== -1" target="_blank">{{ list.title }}</a>
                    <router-link :to="list.path" v-else>{{ list.title }}</router-link>
                  </li>
                </ul>
              </div><!-- end footer-item -->
            </div><!-- end col -->
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="footer-item">
                <h5 class="mb-4 footer-para">CONTACT</h5>
                <ul class="list-item list-item-s1">
                  <li>
                    <img class="" src="@/images/tmp/location.png" alt="location" style="width: 30px;">
                    Irish Square, Upper Denbigh Road, St Asaph, Denbighshire, LL17 0RN, UK
                  </li>
                  <li>
                    <img class="" src="@/images/tmp/mail.png" alt="location" style="width: 30px;margin-right:10px;">
                    <a href="mailto:info@gdmining.com">info@gdmining.com</a>
                  </li>
                  <li>
                    <ul class="styled-icon mt-2">
                      <li>
                        <a href="https://x.com/GDMining_">
                          <img class="s-icon" src="@/images/tmp/twitter.png" alt="twitter">
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/GDMiningBusiness">
                          <img class="s-icon" src="@/images/tmp/facebook1.png" alt="facebook">
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/@gdmining">
                          <img class="s-icon" src="@/images/tmp/youtb.png" alt="ins">
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div><!-- end footer-item -->
            </div><!-- end col -->
          </div>
        </div>
      </div><!-- end row -->
    </div>
    <hr class="bg-white-slim my-0">
<!--    d-flex-->
    <div class="copyright-wrap flex-wrap py-3 align-items-center justify-content-between text-center">
      <!--                  <p class="footer-copy-text py-2" v-html="copyTitle"></p>-->
      <p class="footer-copy-text py-2">
        <a href="https://find-and-update.company-information.service.gov.uk/company/13637255" target="_blank">
          <img src="@/images/tmp/about.png" width="60">
        </a>
        © 2021-2024, GDMining. All Rights Reserved.
      </p>
      <ul class="list-item list-item-s1 list-item-inline">
        <li v-for="(list, i) in footerData.footerListTwo" :key="i">
          <router-link :to="list.path">{{ list.title }}</router-link>
        </li>
      </ul>
    </div><!-- end d-flex -->
  </div><!-- .container -->
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'FooterSection',
  data() {
    return {
      SectionData,
      copyTitle: '© 2017-2024, GDMining. All Rights Reserved.',
      footerData: {
        content: "Our dedicated team of experts, combined with cutting-edge technology, allows us to curate top-tier Cloud Mining investment projects, ensuring that your investment journey is both rewarding and secure.",
        footerList: [
          // {
          //   id: 1,
          //   title: 'LEARN',
          //   navList: [
          //     // {
          //     //   title: 'What is an NFT?',
          //     //   path: '/what-is-an-nft'
          //     // },
          //     {
          //       title: 'What is cryptocurrency?',
          //       path: '/what-is-cryptocurrency'
          //     },
          //     {
          //       title: 'What is web3?',
          //       path: '/what-is-web3'
          //     },
          //     // {
          //     //   title: 'Cryptocurrency Mining',
          //     //   path: 'https://****.com/cryptocurrency-mining'
          //     // },
          //   ]
          // },
          {
            id: 2,
            title: 'QUICK LINK',
            navList: [
              {
                title: 'What is cryptocurrency?',
                path: '/what-is-cryptocurrency'
              },
              {
                title: 'What is web3?',
                path: '/what-is-web3'
              },
              {
                title: 'Affiliates',
                path: '/affiliates'
              },
              {
                title: 'Help Center',
                path: '/help-center'
              },
              // {
              //   title: 'Reviews',
              //   path: '/reviews'
              // },

            ]
          },
          {
            id: 3,
            title: 'COMPANY',
            navList: [
              {
                title: 'About Us',
                path: '/about-us'
              },
              {
                title: 'Terms of Service',
                path: '/terms'
              },
              {
                title: 'Privacy Policy',
                path: '/privacy'
              },
            ]
          }
        ],
      },

    }
  }
}
</script>

<style scoped>
.s-icon {
  color: #1c2b46;
  width: 2.1rem;
  height: 2.1rem;
  line-height: 2.313rem;
  border-radius: 50%;
  background-color: #ffffff;
  display: block;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s;
}

.s-app {
//background-color: #ffffff; margin-left: 20px; width: 8rem;
}

.footer-para {
  color: black;
  font-weight: 600;
}

.on-dark .list-item li a {
  color: gray;
  font-weight: 400;
}

.bg-white-slim {
  background-color: black !important;
//border-top: 3px solid; border: none; height: 1px;
}

.footer-copy-text {
  color: gray;
  font-weight: 500;
}

.list-item {
  li {
    a {
    //color: rgba($white, 0.8);

      &:hover {
        color: #ff8763;
      }

      &::after {
        background-color: black;
      }
    }
  }
}

.list-item-s1,.on-dark .list-item li a, .py-2 {
  color: #0a0a0a;
}

</style>