<template>
  <div class="header-main is-sticky" :class="isTransparent">
    <div class="container">
      <div class="header-wrap">
        <div class="d-flex align-items-center">                <!-- logo -->
          <Logo></Logo>
          <!--              Language-->
          <Language></Language>
        </div>
        <!-- mobile action -->
        <MobileAction></MobileAction>
        <!-- heder search -->
        <!--                <HeaderSearch></HeaderSearch>-->
        <!-- Menu -->
        <Menu classname="btn-dark"></Menu>
        <div class="header-overlay"></div>
      </div><!-- .header-warp-->
    </div><!-- .container-->
  </div><!-- .header-main-->
</template>
<script>
// @ is an alias to /src
import Logo from '@/components/n1/Logo.vue'
import MobileAction from '@/components/n1/MobileAction.vue'
import HeaderSearch from '@/components/n1/HeaderSearch.vue'
import Menu from '@/components/n1/Menu.vue'
import storage from "@/storage";
import Language from "@/components/n1/Language"

export default {
  name: 'HeaderMain',
  props: ['isTransparent'],
  data() {
    return {
      login: false
    }
  },
  components: {
    Logo,
    MobileAction,
    HeaderSearch,
    Menu,
    Language
  },
  mounted() {
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
  },
  methods: {}
}
</script>

<style>
.header-main {
    margin-left: auto;
    margin-right: auto;
    max-width: 2100px;
    padding: 0;
}

</style>
